import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import Scroll from '../../components/Scroll';
import constants from '../../constants';
import { Link } from 'gatsby';
import { LightgalleryItem } from 'react-lightgallery';
import img1 from '../../assets/images/gallery/1.jpg';
import img2 from '../../assets/images/gallery/2.jpg';
import img3 from '../../assets/images/gallery/3.jpg';
import img4 from '../../assets/images/gallery/4.jpg';
import img5 from '../../assets/images/gallery/5.jpg';
import img6 from '../../assets/images/gallery/6.jpg';
import img7 from '../../assets/images/gallery/7.jpg';

const IndexPage = () => (
  <Layout>
    <a href="/hu/palyazatok">
      <StaticImage
        style={{
          position: 'fixed',
          right: '0',
          bottom: '0',
          width: '25%',
          minWidth: '300px',
          zIndex: 9,
        }}
        src="../../assets/images/infog2.png"
        alt=""
      />
    </a>
    <section id="banner">
      <div className="inner">
        <div
          style={{
            width: '16%',
            paddingBottom: '25px',
            minWidth: '150px',
            margin: 'auto',
          }}
        >
          <StaticImage
            src="../../assets/images/logo.png"
            alt="Juhász Testvérek Logó"
          />
        </div>
        <p>Juhász testvérek • Tradícionális pincészet • Egerszalók</p>
        <ul className="actions special">
          <li>
            <a
              href={constants.WEBSHOP_LINK}
              target="__blank"
              className="button primary"
            >
              Webshop
            </a>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="rolunk">
        <a href="#rolunk" className="more">
          Görgess le!
        </a>
      </Scroll>
    </section>

    <section id="rolunk" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <StaticImage src="../../assets/images/rolunk.jpg" alt="Rólunk" />
        </div>
        <div className="content">
          <h2>Rólunk</h2>
          <p>
            A 2021-ben 25 éves fennállását ünneplő borászatot Juhász Attila és
            testvére alapították 1996-ban. A családi borászat története egy
            pici, nagyjából másfél hektáros kertgazdaságból indult. Az első nagy
            ugrás a borászat életében 2002-ben volt, amikor közel 10 hektár új
            telepítésű terület fordult termőre, köztük olyan kiemelkedő dűlők
            is, mint a Paptag, valamint megépült a korszerű feldolgozó az egri
            Kőlyuktetőn található pincében. 2010 szintén kiemelkedő fontosságú
            év volt, ekkor jelent meg a piacon a Juhász Rosé, ami országos
            népszerűséget hozott a borászat számára. 2018-ban a borászat és a
            borok készítésének vezetését is átvette a második generáció Juhász
            Ádám személyében. Ugyanebben az évben adták át a pincészet új
            borászati központját is, amely az ország egyik legkorszerűbb
            technológiájával rendelkezik. Ma már közel 350 hektár szőlő
            gyümölcséből készülnek a Juhász Testvérek borai, melyek magas
            minőségükről, tiszta gyümölcsösségükről és összetéveszthetetlen
            fajtajellegükről ismerhetőek fel. Számos hazai és nemzetközi
            borverseny díjaival büszkélkedhetnek.
          </p>
        </div>
      </section>
    </section>

    <section id="two" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>Filozófiánk</h2>
          <p>
            Végtelen gyümölcsösség a tradíciók és a modern technológia
            ötvözésével.
          </p>
        </header>
      </div>
    </section>

    <section id="three" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/grapes.jpg"
            alt="Szőlőültetvény"
          />
        </div>
        <div className="content">
          <h2>Szőlőültetvény</h2>
          <p>
            A 350 hektáros birtokunk az Egri Borvidéken helyezkedik el. A
            legfontosabb dűlőink Eger, Egerszalók, Egerszólát, Demjén,
            Nagytálya, Andornaktálya és Maklár határaiban találhatók. A
            telepített fajták között megtalálható kékszőlők a Kékfrankos,
            Cabernet Sauvignon, Cabernet Franc, Pinot Noir, Portugieser,
            Zweigelt, Blauburger és Merlot, melyek kitűnő alapot képeznek az
            Egri Bikavér készítéséhez. Fehérborokat a Muscat Ottonel, Irsai
            Olivér, Olaszrizling, Chardonnay, Sauvignon Blanc, Pinot gris és
            Leányka fajtákból állítunk elő. Az ültetvények között egyaránt
            találunk régi és új telepítéseket. Az új telepítéseknél a
            legkorszerűbb művelésmódokat alkalmazzuk. Ezen dűlőkben a termesztés
            az agrár környezetgazdálkodási program keretein belül történik. Az
            ültetvényekben ernyő-, illetve Guyot művelésmóddal dolgozunk. Két
            legfontosabb dűlőnk a mára már sokaknak ismerősen csengő Paptag (10
            ha) és Gesztenyés (6 ha). Ezen két dűlő adja minden évben a
            pincészet csúcs vörösborait.
          </p>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/winery.jpg"
            alt="Az Egri Borvidék"
          />
        </div>
        <div className="content">
          <h2>Az Egri Borvidék</h2>
          <p>
            Az Egri Borvidék a 6040 hektár területű szőlőültetvényeivel,
            Magyarország északkeleti részén, a Bükk hegység nyugati lejtőin
            található, 120 km-re a fővárostól, Budapesttől. Az eredetvédelem
            szempontjából 2 körzetre válik szét: Egri és Debrői körzetre, mely
            magába foglalja Eger városát és még 19 kisebb települést. Ez a
            szülőföldje a kiváló, magas minőségű nemes boroknak, melyeknek a
            vörösesbarna agyag és vulkanikus kőzetű talaj rendkívül gazdag
            illatot, összetevőket kölcsönöz. A város és környéke híres
            történelmi borvidék, a helybéli lakosok 1000 évvel ezelőtt fogtak
            bele szőlőt termeszteni, kiváló borokat készíteni. A rendszerváltást
            követően az Egri Borvidék rendkívül gyors fejlődésen ment keresztül,
            a régi és új szőlőfajták legjobb dűlőkbe való újratelepítésen át a
            borvidék területe is jelentősen növekedett.
          </p>
        </div>
      </section>

      {/* <section className="spotlight">
        <div className="image">
          <StaticImage src="../../assets/images/wines.jpeg" alt="Boraink" />
        </div>
        <div className="content">
          <h2>
            Boraink
          </h2>
          <p>
          A legmegfelelőbb termőhely kiválasztásával, a visszafogott terméssel és a legkorszerűbb feldolgozással boraink állandó jó minőségét garantáljuk. Munkánkat igazolja a hazai versenyeken elért aranyérmek és díjak. 
          </p>
          <a href="/boraink" className="button primary">
            Tovább
          </a>
        </div>
      </section> */}
    </section>

    {/* <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Fejlesztéseink
          </h2>
          <p>
            A modern borászati technológiával...
          </p>
        </header>
      </div>
    </section> */}

    <section className="wrapper style2">
      <div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1}>
                    <StaticImage
                      src="../../assets/images/gallery/1.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2}>
                    <StaticImage
                      src="../../assets/images/gallery/2.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img3}>
                    <StaticImage
                      src="../../assets/images/gallery/3.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img4}>
                    <StaticImage
                      src="../../assets/images/gallery/4.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img5}>
                    <StaticImage
                      src="../../assets/images/gallery/5.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img6}>
                    <StaticImage
                      src="../../assets/images/gallery/6.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img7}>
                    <StaticImage
                      src="../../assets/images/gallery/7.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Rendezvények</h2>
          <p>
            A pincészethez tartozik egy minden igényt kielégítő vendégfogadó
            komplexum is, amely egyszerre idézi fel a klasszikus borospince
            hangulatot, ugyanakkor modern kommunikációs eszközökkel segít
            megismertetni vendégeinkkel a borvidéket, a dűlőinket,
            végigkövethetik a szőlő útját a szürettől egészen a palackozásig. A
            meghitt borkóstoló sarkok kiváló helyszínt biztosítanak egyedi
            borkóstolásokra, az impozáns kóstoló terem pedig családi, baráti,
            céges rendezvények, exkluzív fogadások, esküvők számára is ideális
            100 főig.
          </p>
        </header>
        <ul className="features">
          <li>
            <Link to="https://www.juhaszvin.hu/hu/#kapcsolat">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/glass.png"
                  alt="Borkóstoló"
                />
              </div>
              <h4>Borkóstoló</h4>
            </Link>
          </li>
          <li>
            <Link to="https://www.juhaszvin.hu/hu/#kapcsolat">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/circles.png"
                  alt="Esküvő"
                />
              </div>
              <h4>Esküvő</h4>
            </Link>
          </li>
          <li>
            <Link to="https://www.juhaszvin.hu/hu/#kapcsolat">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/hand.png"
                  alt="Kitelepült Borkóstoló"
                />
              </div>
              <h4>Kitelepült Borkóstoló</h4>
            </Link>
          </li>
          <li>
            <Link to="https://www.juhaszvin.hu/hu/#kapcsolat">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/user.png"
                  alt="Egyéni Látogatás"
                />
              </div>
              <h4>Egyéni Látogatás</h4>
            </Link>
          </li>
          <li>
            <Link to="https://www.juhaszvin.hu/hu/#kapcsolat">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/handshake.png"
                  alt="Esküvő- és Rendezvényszervező Partnereink"
                />
              </div>
              <h4>Esküvő- és Rendezvényszervező Partnereink</h4>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
